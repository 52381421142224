import React, { useState, useMemo, useEffect, useCallback } from "react";
import { DataGrid, getGridStringOperators } from "@mui/x-data-grid";
import { Box, Typography, TextField, Button, IconButton, useMediaQuery, Tooltip } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import axios from "axios";
import RefreshIcon from "@mui/icons-material/Refresh";
import Loader from "../components/Loader";
import { dataGridStyles } from "../styles";
import { getUserInfoFromToken } from "../services/authService";

const LeadsReports = () => {
  const token = localStorage.getItem("authToken");
  const userInfo = useMemo(() => getUserInfoFromToken(), []);
  const { role, username, team } = userInfo;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fromDate, setFromDate] = useState(dayjs().startOf("day"));
  const [toDate, setToDate] = useState(dayjs().endOf("day"));
  const [customFilter, setCustomFilter] = useState("");
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [columnWidths, setColumnWidths] = useState({});
  const isMobile = useMediaQuery("(max-width:600px)");
  const stringOnlyContainsOperators = getGridStringOperators().filter(
    (operator) => operator.value === "contains"
  );

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const params = {
        fromDate: fromDate.format("YYYY-MM-DD HH:mm:ss"),
        toDate: toDate.format("YYYY-MM-DD HH:mm:ss")
      };
      const response = await axios.get("https://backend.moorpan.com/get-leads-reports", { params, headers: { Authorization: `Bearer ${token}` } });
      let items = response.data.items;
      if (role === "user" && username) {
        // Для юзера – только свои записи
        items = items.filter((item) => item.custom4 === username);
      } else if (role === "tlead" && Array.isArray(team)) {
        // Для tlead – записи его и его команды (если team включает запись, либо запись принадлежит самому лидеру)
        items = items.filter((item) => item.custom4 === username || team.includes(item.custom4));
      }
      // Для остальных (админа и т.п.) дополнительно применяется фильтр, если указан
      if (role !== "user" && customFilter) {
        items = items.filter((item) => item.custom4 === customFilter);
      }
      setData(items);
    } catch (error) {
      console.error("Ошибка при получении данных leads reports:", error);
    } finally {
      setLoading(false);
    }
  }, [fromDate, toDate, role, username, team, customFilter, token]);

  const fetchColumnSettings = useCallback(async () => {
    try {
      const response = await axios.get("https://backend.moorpan.com/get-column-settings", {
        params: { username, tableName: "LeadsReports" },
        headers: { Authorization: `Bearer ${token}` }
      });
      if (response.data) {
        const visibilityModel = response.data.reduce((acc, col) => {
          acc[col.field] = !col.hide;
          return acc;
        }, {});
        const widths = response.data.reduce((acc, col) => {
          if (col.width) acc[col.field] = col.width;
          return acc;
        }, {});
        setColumnVisibilityModel(visibilityModel);
        setColumnWidths(widths);
      }
    } catch (error) {
      console.error("Ошибка при получении настроек колонок:", error);
    }
  }, [username, token]);

  const handleColumnResize = useCallback(async (params) => {
    const updatedWidths = { ...columnWidths, [params.colDef.field]: params.width };
    setColumnWidths(updatedWidths);
    try {
      await axios.post(
        "https://backend.moorpan.com/save-column-settings",
        {
          username,
          tableName: "LeadsReports",
          columnConfig: Object.keys(updatedWidths).map((field) => ({
            field,
            hide: false,
            width: updatedWidths[field]
          }))
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      console.log("Ширина колонок для LeadsReports сохранена.");
    } catch (error) {
      console.error("Ошибка сохранения ширины колонок:", error);
    }
  }, [username, columnWidths, token]);

  const handleColumnVisibilityChange = useCallback(async (newModel) => {
    setColumnVisibilityModel(newModel);
    const columnConfig = Object.keys(newModel).map((field) => ({
      field,
      hide: !newModel[field],
      width: columnWidths[field] || null
    }));
    try {
      await axios.post(
        "https://backend.moorpan.com/save-column-settings",
        { username, tableName: "LeadsReports", columnConfig },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      console.log("Настройки видимости для LeadsReports сохранены.");
    } catch (error) {
      console.error("Ошибка сохранения настроек видимости:", error);
    }
  }, [username, columnWidths, token]);

  const setToday = () => { setFromDate(dayjs().startOf("day")); setToDate(dayjs().endOf("day")); };
  const setYesterday = () => { setFromDate(dayjs().subtract(1, "day").startOf("day")); setToDate(dayjs().subtract(1, "day").endOf("day")); };
  const setLastWeek = () => { setFromDate(dayjs().subtract(1, "week").startOf("week")); setToDate(dayjs().subtract(1, "week").endOf("week")); };
  const setLastMonth = () => { setFromDate(dayjs().subtract(1, "month").startOf("month")); setToDate(dayjs().subtract(1, "month").endOf("month")); };
  const setThisWeek = () => { setFromDate(dayjs().startOf("week")); setToDate(dayjs().endOf("week")); };
  const setThisMonth = () => { setFromDate(dayjs().startOf("month")); setToDate(dayjs().endOf("month")); };

  useEffect(() => { fetchData(); }, [fetchData]);
  useEffect(() => { fetchColumnSettings(); }, [fetchColumnSettings]);

  const columns = useMemo(() => [
    { field: "ID", headerName: "ID", minWidth: 60, align: "center", headerAlign: "center", filterOperators: stringOnlyContainsOperators },
    { field: "projectTitle", headerName: "OFFER", minWidth: 100, align: "center", headerAlign: "center", filterOperators: stringOnlyContainsOperators },
    { field: "countryName", headerName: "COUNTRY", minWidth: 80, align: "center", headerAlign: "center", filterOperators: stringOnlyContainsOperators },
    { field: "custom", headerName: "custom", minWidth: 80, align: "center", headerAlign: "center", filterOperators: stringOnlyContainsOperators },
    { field: "custom1", headerName: "custom1", minWidth: 80, align: "center", headerAlign: "center", filterOperators: stringOnlyContainsOperators },
    { field: "custom2", headerName: "custom2", minWidth: 80, align: "center", headerAlign: "center", filterOperators: stringOnlyContainsOperators },
    { field: "custom3", headerName: "custom3", minWidth: 80, align: "center", headerAlign: "center", filterOperators: stringOnlyContainsOperators },
    { field: "custom4", headerName: "custom4", minWidth: 80, align: "center", headerAlign: "center", filterOperators: stringOnlyContainsOperators },
    { field: "custom5", headerName: "custom5", minWidth: 80, align: "center", headerAlign: "center", filterOperators: stringOnlyContainsOperators },
    { field: "responseInfo", headerName: "response INFO", minWidth: 200, align: "left", headerAlign: "center", filterOperators: stringOnlyContainsOperators, renderCell: (params) => (
      <Tooltip title={params.value}>
        <span style={{ whiteSpace: "normal", wordBreak: "break-word" }}>{params.value}</span>
      </Tooltip>
    ) },
    { field: "REFERRER", headerName: "REFERRER", minWidth: 100, align: "center", headerAlign: "center", filterOperators: stringOnlyContainsOperators },
    { field: "TIME", headerName: "TIME", minWidth: 100, align: "center", headerAlign: "center", filterOperators: stringOnlyContainsOperators, renderCell: (params) => dayjs(params.value).format("DD/MM/YYYY HH:mm:ss") }
  ].map((col) => ({ ...col, width: columnWidths[col.field] || col.minWidth })), [columnWidths, stringOnlyContainsOperators]);

  const rows = useMemo(() => {
    return data.map((row) => {
      let parsedResponse = {};
      let parsedRequest = {};
      try { parsedResponse = row.response ? JSON.parse(row.response) : {}; } catch (error) { console.error("Ошибка парсинга row.response", error); }
      try { parsedRequest = row.request ? JSON.parse(row.request) : {}; } catch (error) { console.error("Ошибка парсинга row.request", error); }
      const responseInfo = (() => {
        if (parsedResponse && Object.prototype.hasOwnProperty.call(parsedResponse, "status")) {
          return parsedResponse.status ? "OK" : "Error";
        }
        if (parsedResponse && parsedResponse.message) {
          if (typeof parsedResponse.message === "string") return parsedResponse.message.trim() === "" ? "OK" : parsedResponse.message;
          if (Array.isArray(parsedResponse.message)) return parsedResponse.message.length === 0 ? "OK" : parsedResponse.message.join(", ");
          return parsedResponse.message;
        }
        if (parsedResponse && parsedResponse.error) {
          if (typeof parsedResponse.error === "string") return parsedResponse.error.trim() === "" ? "OK" : parsedResponse.error;
          if (Array.isArray(parsedResponse.error)) return parsedResponse.error.length === 0 ? "OK" : parsedResponse.error.join(", ");
          return parsedResponse.error;
        }
        return "OK";
      })();
      return { ...row, TIME: row.TIME || row.timestamp, responseInfo, REFERRER: parsedRequest.referer || "" };
    });
  }, [data]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ position: "relative", padding: 2 }}>
        {isMobile && (
          <IconButton
            onClick={fetchData}
            sx={{
              position: "fixed",
              left: "12px",
              bottom: "12px",
              zIndex: 9999,
              backgroundColor: "#0BDF79",
              color: "#FFFFFF",
              width: "56px",
              height: "56px",
              borderRadius: "50%",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              "&:hover": { backgroundColor: "#0ACF6F" }
            }}
          >
            <RefreshIcon />
          </IconButton>
        )}
        <Typography variant="h4" align="center" gutterBottom>Leads Reports</Typography>
        <Box sx={{ display: "flex", justifyContent: "center", flexWrap: "wrap", gap: 2, mb: 2 }}>
          <DatePicker label="From Date" value={fromDate} onChange={(date) => setFromDate(date)} format="DD/MM/YYYY" />
          <DatePicker label="To Date" value={toDate} onChange={(date) => setToDate(date)} format="DD/MM/YYYY" />
          <Button variant="contained" onClick={fetchData}>Refresh</Button>
        </Box>
        {role !== "user" && (
          <Box sx={{ mb: 2, display: "flex", justifyContent: "center", gap: 1 }}>
            <TextField label="Filter by custom4" value={customFilter} onChange={(e) => setCustomFilter(e.target.value)} />
            <Button variant="contained" onClick={fetchData}>Apply</Button>
          </Box>
        )}
        <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap", justifyContent: "center", mb: 2 }}>
          <Button onClick={setToday} variant="outlined">Today</Button>
          <Button onClick={setYesterday} variant="outlined">Yesterday</Button>
          <Button onClick={setLastWeek} variant="outlined">Last Week</Button>
          <Button onClick={setLastMonth} variant="outlined">Last Month</Button>
          <Button onClick={setThisWeek} variant="outlined">This Week</Button>
          <Button onClick={setThisMonth} variant="outlined">This Month</Button>
        </Box>
        {loading ? (
          <Loader />
        ) : (
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={25}
            rowsPerPageOptions={[25, 50, 100, { value: -1, label: "All" }]}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={handleColumnVisibilityChange}
            onColumnWidthChange={handleColumnResize}
            getRowId={(row) => row.ID || row.id}
            sx={{ ...dataGridStyles }}
          />
        )}
      </Box>
    </LocalizationProvider>
  );
};

export default LeadsReports;
