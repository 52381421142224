import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import DashboardLayoutBranding from './DashboardLayoutBranding';
import Login from './pages/Login';
import Register from './pages/Register';
import MediaBuyerAnalytics from './pages/MediaBuyerAnalytics';
import ProtectedRoute from './components/ProtectedRoute';
import AdvertiserAnalytics from './pages/AdvertiserAnalytics';
import AccountsAnalitics from './pages/AccountsAnalitics';
import DomainsAnalitics from './pages/DomainsAnalitics';
import Leaderboard from './pages/Leaderboard';
import FinancialAnalytics from './pages/FinancialAnalytics';

import AccountsAnaliticsGoogle from './pages/AccountsAnaliticsGoogle';
import FinancialAnalyticsGoogle from './pages/FinancialAnalyticsGoogle';
import LeadsReports from './pages/LeadsReports';
import Leadsboard from './pages/Leadsboard';

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />

        <Route path="/register" element={
          <ProtectedRoute allowedRoles={['admin']}>
            <Register />
          </ProtectedRoute>
        } />

        <Route
          path="/"
          element={
            <ProtectedRoute allowedRoles={['admin', 'user', 'tlead', 'finance', 'accm']}>
              <DashboardLayoutBranding />
            </ProtectedRoute>
          }
        />
        <Route
          path="/media-buyer-analytics"
          element={
            <ProtectedRoute allowedRoles={['admin', 'user', 'tlead', 'finance']}>
              <MediaBuyerAnalytics />
            </ProtectedRoute>
          }
        />
        <Route
          path="/advertiser-analytics"
          element={
            <ProtectedRoute allowedRoles={['admin']}>
              <AdvertiserAnalytics />
            </ProtectedRoute>
          }
        />
        <Route
          path="/accounts-analytics"
          element={
            <ProtectedRoute allowedRoles={['admin', 'user', 'tlead', 'finance', 'accm']}>
              <AccountsAnalitics />
            </ProtectedRoute>
          }
        />
        <Route
          path="/domains-analytics"
          element={
            <ProtectedRoute allowedRoles={['admin', 'user', 'tlead']}>
              <DomainsAnalitics />
            </ProtectedRoute>
          }
        />
        <Route
          path="/leaderboard"
          element={
            <ProtectedRoute allowedRoles={['admin', 'user', 'tlead']}>
              <Leadsboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/financial-analytics"
          element={
            <ProtectedRoute allowedRoles={['admin', 'user', 'finance', 'tlead', 'accm']}>
              <FinancialAnalytics />
            </ProtectedRoute>
          }
        />
        <Route
          path="/accounts-analytics-google"
          element={
            <ProtectedRoute allowedRoles={['admin', 'user', 'tlead', 'finance', 'accm']}>
              <AccountsAnaliticsGoogle />
            </ProtectedRoute>
          }
        />
        <Route
          path="/financial-analytics-google"
          element={
            <ProtectedRoute allowedRoles={['admin', 'user', 'finance', 'tlead', 'accm']}>
              <FinancialAnalyticsGoogle />
            </ProtectedRoute>
          }
        />
        <Route
          path="/leads-reports"
          element={
            <ProtectedRoute allowedRoles={['admin', 'user', 'tlead']}>
              <LeadsReports />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
}
