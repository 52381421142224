import React, { useState, useMemo, useEffect, useCallback } from "react";
import { DataGrid, getGridStringOperators } from "@mui/x-data-grid";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  TextField,
  Divider,
  Button,
  useMediaQuery,
  IconButton
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import axios from "axios";
import Loader from "../components/Loader";
import { dataGridStyles } from "../styles";
import RefreshIcon from "@mui/icons-material/Refresh";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { getUserInfoFromToken } from "../services/authService";

const Leadsboard = () => {
  const token = localStorage.getItem('authToken');
  const userInfo = useMemo(() => getUserInfoFromToken(), []);
  const { role, username, team } = userInfo;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fromDate, setFromDate] = useState(dayjs().startOf("day"));
  const [toDate, setToDate] = useState(dayjs().endOf("day"));
  const [selectedBuyer, setSelectedBuyer] = useState("");
  const [selectedSaleStatus, setSelectedSaleStatus] = useState("");
  const [selectedHasFTD, setSelectedHasFTD] = useState("");
  const [emailFilter, setEmailFilter] = useState("");
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [testColumnVisibilityModel, setTestColumnVisibilityModel] = useState({});
  const [testColumnWidths, setTestColumnWidths] = useState({});
  const [conversionsColumnVisibilityModel, setConversionsColumnVisibilityModel] = useState({});
  const [conversionsColumnWidths, setConversionsColumnWidths] = useState({});
  const [showTestTable, setShowTestTable] = useState(false);
  const [testData, setTestData] = useState([]);
  const [conversionsData, setConversionsData] = useState([]);
  const [showConversionsTable, setShowConversionsTable] = useState(false);
  const [columnWidths, setColumnWidths] = useState({});

  const isMobile = useMediaQuery("(max-width:600px)");

  const stringOnlyContainsOperators = getGridStringOperators().filter(
    (operator) => operator.value === "contains"
  );

  const fetchConversionsData = useCallback(async () => {
    setLoading(true);
    try {
      const params = {
        fromDate: fromDate.format("YYYY-MM-DD HH:mm:ss"),
        toDate: toDate.format("YYYY-MM-DD HH:mm:ss"),
      };

      if (role === "user" && username) {
        params.custom4 = username;
      }

      const response = await axios.get(
        "https://backend.moorpan.com/get-leads-and-conversions",
        { params,
          headers: {
            Authorization: `Bearer ${token}`
          } 
        }
      );

      let conversions = response.data.conversions;

      if (role === "tlead" && Array.isArray(team)) {
        conversions = conversions.filter((item) => team.includes(item.custom4));
      }

      setConversionsData(conversions);
    } catch (error) {
      console.error("Ошибка при загрузке данных конверсий:", error);
    } finally {
      setLoading(false);
    }
  }, [fromDate, toDate, role, username, team, token]);

  const handleToggleConversionsTable = useCallback(async () => {
    if (!showConversionsTable) {
      await fetchConversionsData();
    }
    setShowConversionsTable((prev) => !prev);
  }, [showConversionsTable, fetchConversionsData]);

  const fetchTestData = useCallback(async () => {
    setLoading(true);
    try {
      const params = {
        fromDate: fromDate.format("YYYY-MM-DD HH:mm:ss"),
        toDate: toDate.format("YYYY-MM-DD HH:mm:ss"),
        itemsPerPage: 1000,
      };

      if (role === "user" && username) {
        params.custom4 = username;
      }

      const response = await axios.get(
        "https://backend.moorpan.com/get-test-leads",
        { params,
          headers: {
            Authorization: `Bearer ${token}`
          } 
        }
      );
      
      let items = response.data.items;

      if (role === "tlead" && Array.isArray(team)) {
        items = items.filter((item) => team.includes(item.custom4));
      }

      setTestData(items);
    } catch (error) {
      console.error("Ошибка при загрузке тестовых лидов:", error);
    } finally {
      setLoading(false);
    }
  }, [fromDate, toDate, role, username, team, token]);

  const setToday = () => {
    setFromDate(dayjs().startOf("day"));
    setToDate(dayjs().endOf("day"));
  };

  const setYesterday = () => {
    setFromDate(dayjs().subtract(1, "day").startOf("day"));
    setToDate(dayjs().subtract(1, "day").endOf("day"));
  };

  const setLastWeek = () => {
    setFromDate(dayjs().subtract(1, "week").startOf("week"));
    setToDate(dayjs().subtract(1, "week").endOf("week"));
  };

  const setLastMonth = () => {
    setFromDate(dayjs().subtract(1, "month").startOf("month"));
    setToDate(dayjs().subtract(1, "month").endOf("month"));
  };

  const setThisWeek = () => {
    setFromDate(dayjs().startOf("week"));
    setToDate(dayjs().endOf("week"));
  };

  const setThisMonth = () => {
    setFromDate(dayjs().startOf("month"));
    setToDate(dayjs().endOf("month"));
  };

  const handleToggleTestTable = useCallback(() => {
    if (!showTestTable) {
      fetchTestData();
    }
    setShowTestTable((prev) => !prev);
  }, [showTestTable, fetchTestData]);

  const handleColumnResize = useCallback(async (params, currentTableName) => {
    let updatedWidths = {};
    let setWidths;

    if (currentTableName === "Leaderboard") {
      updatedWidths = { ...columnWidths, [params.colDef.field]: params.width };
      setWidths = setColumnWidths;
    } else if (currentTableName === "TestTable") {
      updatedWidths = { ...testColumnWidths, [params.colDef.field]: params.width };
      setWidths = setTestColumnWidths;
    } else if (currentTableName === "ConversionsTable") {
      updatedWidths = { ...conversionsColumnWidths, [params.colDef.field]: params.width };
      setWidths = setConversionsColumnWidths;
    } else {
      return;
    }

    setWidths(updatedWidths);

    try {
      await axios.post("https://backend.moorpan.com/save-column-settings", {
        username,
        tableName: currentTableName,
        columnConfig: Object.keys(updatedWidths).map((field) => ({
          field,
          hide: false,
          width: updatedWidths[field],
        })),
        headers: {
          Authorization: `Bearer ${token}`
        } 
      });
      console.log(`Ширина колонок для ${currentTableName} успешно сохранена.`);
    } catch (error) {
      console.error(`Ошибка сохранения ширины для ${currentTableName}:`, error);
    }
  }, [username, columnWidths, testColumnWidths, conversionsColumnWidths, token]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const params = {
        fromDate: fromDate.format("YYYY-MM-DD HH:mm:ss"),
        toDate: toDate.format("YYYY-MM-DD HH:mm:ss"),
      };

      if (role === "user" && username) {
        params.custom4 = username;
      }

      const response = await axios.get(
        "https://backend.moorpan.com/get-leads-new",
        { params,
          headers: {
            Authorization: `Bearer ${token}`
          }
         }
      );

      let items = response.data.leads;

      if (role === "tlead" && Array.isArray(team)) {
        items = items.filter((item) => team.includes(item.custom4));
      }

      setData(items);
    } catch (error) {
      console.error("Ошибка при загрузке данных:", error);
    } finally {
      setLoading(false);
    }
  }, [fromDate, toDate, role, username, team, token]);

  const fetchAllData = useCallback(async () => {
    setLoading(true);
    try {
      await fetchData();
      await fetchTestData();
      await fetchConversionsData();
    } catch (error) {
      console.error("Ошибка при загрузке данных:", error);
    } finally {
      setLoading(false);
    }
  }, [fetchData, fetchTestData, fetchConversionsData]);

  const fetchColumnSettings = useCallback(async (currentTableName) => {
    try {
      const response = await axios.get(
        "https://backend.moorpan.com/get-column-settings",
        { params: { username, tableName: currentTableName },         headers: {
          Authorization: `Bearer ${token}`
        }  }
      );

      if (response.data) {
        const visibilityModel = response.data.reduce((acc, col) => {
          acc[col.field] = !col.hide;
          return acc;
        }, {});

        const widths = response.data.reduce((acc, col) => {
          if (col.width) acc[col.field] = col.width;
          return acc;
        }, {});

        if (currentTableName === "Leaderboard") {
          setColumnVisibilityModel(visibilityModel);
          setColumnWidths(widths);
        } else if (currentTableName === "TestTable") {
          setTestColumnVisibilityModel(visibilityModel);
          setTestColumnWidths(widths);
        } else if (currentTableName === "ConversionsTable") {
          setConversionsColumnVisibilityModel(visibilityModel);
          setConversionsColumnWidths(widths);
        }
      }
    } catch (error) {
      console.error(`Ошибка при получении настроек для ${currentTableName}:`, error);
    }
  }, [username, token]);

  useEffect(() => {
    fetchData();
    fetchTestData();
    fetchConversionsData();
  }, [fetchData, fetchTestData, fetchConversionsData]);

  useEffect(() => {
    fetchColumnSettings("Leaderboard");
    fetchColumnSettings("TestTable");
    fetchColumnSettings("ConversionsTable");
  }, [fetchColumnSettings]);

  const handleColumnVisibilityChange = useCallback(async (newVisibilityModel, currentTableName) => {
    let setVisibilityModel;
    let currentColumnWidths;

    if (currentTableName === "Leaderboard") {
      setVisibilityModel = setColumnVisibilityModel;
      currentColumnWidths = columnWidths;
    } else if (currentTableName === "TestTable") {
      setVisibilityModel = setTestColumnVisibilityModel;
      currentColumnWidths = testColumnWidths;
    } else if (currentTableName === "ConversionsTable") {
      setVisibilityModel = setConversionsColumnVisibilityModel;
      currentColumnWidths = conversionsColumnWidths;
    } else {
      return;
    }

    setVisibilityModel(newVisibilityModel);

    const columnConfig = Object.keys(newVisibilityModel).map((field) => ({
      field,
      hide: !newVisibilityModel[field],
      width: currentColumnWidths[field] || null,
    }));

    try {
      await axios.post("https://backend.moorpan.com/save-column-settings", {
        username,
        tableName: currentTableName,
        columnConfig,
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      console.log(`Настройки видимости для ${currentTableName} сохранены.`);
    } catch (error) {
      console.error(`Ошибка при сохранении видимости для ${currentTableName}:`, error);
    }
  }, [username, columnWidths, testColumnWidths, conversionsColumnWidths, token]);

  const rows = useMemo(() => {
    console.log(data)
    return data
      .map((row) => ({
        ...row,
        formattedSignupDate: dayjs(row.signup_date).format("DD/MM/YYYY HH:mm:ss"),
        id: row.id
      }))
      .filter(
        (row) =>
          (selectedBuyer ? row.custom4 === selectedBuyer : true) &&
          (selectedSaleStatus ? row.sale_status === selectedSaleStatus : true) &&
          (selectedHasFTD
            ? selectedHasFTD === "Yes"
              ? row.has_ftd === 1
              : row.has_ftd === 0
            : true) &&
          (emailFilter
            ? row.email.toLowerCase().includes(emailFilter.toLowerCase())
            : true)
      )
      .sort((a, b) => dayjs(b.signup_date).diff(dayjs(a.signup_date)));
  }, [data, selectedBuyer, selectedSaleStatus, selectedHasFTD, emailFilter]);

  const buyers = [...new Set(data.map((item) => item.custom4).filter(Boolean))];
  const saleStatuses = [
    ...new Set(data.map((item) => item.sale_status).filter(Boolean)),
  ];

  const columns = useMemo(() => {
    return [
    {
      field: "country_name",
      headerName: "Country",
      minWidth: 60,
      align: "center",
      headerAlign: "center",
      sortable: false,
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: "city_name",
      headerName: "City",
      minWidth: 60,
      align: "center",
      headerAlign: "center",
      sortable: false,
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: "custom",
      headerName: "Custom",
      minWidth: 60,
      align: "center",
      headerAlign: "center",
      sortable: false,
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: "custom1",
      headerName: "Custom1",
      minWidth: 60,
      align: "center",
      headerAlign: "center",
      sortable: false,
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: "custom2",
      headerName: "Custom2",
      minWidth: 60,
      align: "center",
      headerAlign: "center",
      sortable: false,
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: "custom3",
      headerName: "Custom3",
      minWidth: 60,
      align: "center",
      headerAlign: "center",
      sortable: false,
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: "custom4",
      headerName: "Custom4",
      minWidth: 40,
      align: "center",
      headerAlign: "center",
      sortable: false,
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: "custom5",
      headerName: "Custom5",
      minWidth: 60,
      align: "center",
      headerAlign: "center",
      sortable: false,
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: "formattedSignupDate",
      headerName: "Signup Date",
      minWidth: 60,
      align: "center",
      headerAlign: "center",
      sortable: false,
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 60,
      align: "center",
      headerAlign: "center",
      sortable: false,
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: "customer_id",
      headerName: "Customer ID",
      minWidth: 60,
      align: "center",
      headerAlign: "center",
      sortable: false,
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: "country_code",
      headerName: "Country Code",
      minWidth: 60,
      align: "center",
      headerAlign: "center",
      sortable: false,
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: "sale_status",
      headerName: "Sale Status",
      minWidth: 60,
      align: "center",
      headerAlign: "center",
      sortable: false,
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: "campaign_hash",
      headerName: "Offer Hash",
      minWidth: 60,
      align: "center",
      headerAlign: "center",
      sortable: false,
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: "has_ftd",
      headerName: "Has Conversion",
      minWidth: 60,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (params.value === 1 ? "Yes" : "No"),
      sortable: false,
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: "broker_autologin_click",
      headerName: "Has Autologin",
      minWidth: 60,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (params.value === null ? "No" : "Yes"),
      sortable: false,
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: "details",
      headerName: "Details",
      minWidth: 60,
      align: "center",
      headerAlign: "center",
      sortable: false,
      filterOperators: stringOnlyContainsOperators,
      renderCell: (params) => (
        <div
          style={{
            maxHeight: "100%",
            overflowX: "hidden",
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p
            style={{
              margin: "12px 0 12px 0",
              lineHeight: "100%",
              fontWeight: 400,
            }}
          >
            Offer Name: <b>{params.row.lead_request_offer_name}</b>
          </p>
          <p
            style={{
              margin: "0 0 12px 0",
              lineHeight: "100%",
              fontWeight: 400,
            }}
          >
            Offer Website: <b>{params.row.lead_request_offer_website}</b>
          </p>
          <p
            style={{
              margin: "0 auto 12px auto",
              maxWidth: 290,
              textWrap: "wrap",
              lineHeight: "100%",
              fontWeight: 400,
            }}
          >
            Offer Comment: <b>{params.row.lead_request_comment}</b>
          </p>
        </div>
      ),
    },
  ].map((col) => ({
    ...col,
    width: columnWidths[col.field] || col.width,
  }));
}, [stringOnlyContainsOperators, columnWidths]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{position: 'relative'}}>
        {isMobile && (
          <IconButton
            onClick={fetchAllData}
            sx={{
              position: 'fixed',
              left: '12px',
              bottom: '12px',
              zIndex: 999999,
              backgroundColor: '#0BDF79',
              color: '#FFFFFF',
              width: '56px',
              height: '56px',
              borderRadius: '50%',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              '&:hover': {
                backgroundColor: '#0ACF6F',
              },
            }}
          >
            <RefreshIcon />
          </IconButton>

        )}
        <Typography variant="h4" align="center" gutterBottom sx={{margin: '0 0 40px 0'}}>
          Lead's board
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "flex-start", flexDirection: isMobile ? "column" : "row", }}>
          <DatePicker
            label="From Date"
            value={fromDate}
            onChange={(date) => setFromDate(date)}
            format="DD/MM/YYYY"
          />
          <Divider
            orientation={isMobile ? "horizontal" : "vertical"}
            variant="middle"
            flexItem
            sx={{ margin: isMobile ? '24px 0' : "0 12px" }}
          />
          <DatePicker
            label="To Date"
            value={toDate}
            onChange={(date) => setToDate(date)}
            format="DD/MM/YYYY"
          />
          {!isMobile && (
            <IconButton
              onClick={fetchAllData}
              color="primary"
              sx={{ marginLeft: isMobile ? '0' : "12px" }}
            >
              <RefreshIcon />
            </IconButton>
          )}
        </Box>

        <Box sx={{ display: "flex", gap: isMobile ? 2 : 6, mb: 2, mt: 2, flexDirection: isMobile ? "column" : "row", }}>
          <Button onClick={setToday} variant="outlined" size={isMobile ? "large" : "small"}>
            Today
          </Button>
          <Button onClick={setYesterday} variant="outlined" size={isMobile ? "large" : "small"}>
            Yesterday
          </Button>
          <Button onClick={setLastWeek} variant="outlined" size={isMobile ? "large" : "small"}>
            Last Week
          </Button>
          <Button onClick={setLastMonth} variant="outlined" size={isMobile ? "large" : "small"}>
            Last Month
          </Button>
          <Button onClick={setThisWeek} variant="outlined" size={isMobile ? "large" : "small"}>
            This Week
          </Button>
          <Button onClick={setThisMonth} variant="outlined" size={isMobile ? "large" : "small"}>
            This Month
          </Button>
        </Box>

        <Box sx={{ mt: 2, mb: 2, display: "flex", gap: 2, flexDirection: isMobile ? "column" : "row", }}>
          <Button
            variant="outlined"
            onClick={handleToggleTestTable}
            endIcon={showTestTable ? <VisibilityOffIcon /> : <VisibilityIcon />}
            size="large"
          >
            {showTestTable ? "Hide Tests Table" : "Show Tests Table"}
          </Button>

          <Button
            variant="outlined"
            onClick={handleToggleConversionsTable}
            endIcon={
              showConversionsTable ? <VisibilityOffIcon /> : <VisibilityIcon />
            }
            size="large"
          >
            {showConversionsTable
              ? "Hide Conversions Table"
              : "Show Conversions Table"}
          </Button>
        </Box>

        <Typography variant="h5" gutterBottom sx={{ margin: "0 0 12px 0" }}>
          Filter by params
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: "flex-start",
            mb: 2,
          }}
        >
          <Select
            value={selectedBuyer}
            onChange={(e) => setSelectedBuyer(e.target.value)}
            displayEmpty
            sx={{
              minWidth: 150,
              margin: isMobile ? "0 0 12px 0" : "0 12px 0 0",
            }}
          >
            <MenuItem value="">All Buyers</MenuItem>
            {buyers.map((buyer) => (
              <MenuItem key={buyer} value={buyer}>
                {buyer}
              </MenuItem>
            ))}
          </Select>

          <Select
            value={selectedSaleStatus}
            onChange={(e) => setSelectedSaleStatus(e.target.value)}
            displayEmpty
            sx={{
              minWidth: 150,
              margin: isMobile ? "0 0 12px 0" : "0 12px 0 0",
            }}
          >
            <MenuItem value="">All Statuses</MenuItem>
            {saleStatuses.map((status) => (
              <MenuItem key={status} value={status}>
                {status}
              </MenuItem>
            ))}
          </Select>

          <Select
            value={selectedHasFTD}
            onChange={(e) => setSelectedHasFTD(e.target.value)}
            displayEmpty
            sx={{
              minWidth: 150,
              margin: isMobile ? "0 0 12px 0" : "0 12px 0 0",
            }}
          >
            <MenuItem value="">All Conversions</MenuItem>
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </Select>

          <TextField
            label="Email"
            value={emailFilter}
            onChange={(e) => setEmailFilter(e.target.value)}
            sx={{ minWidth: 300 }}
          />
        </Box>

        {showTestTable && (
          <>
            {loading ? (
              <Loader />
            ) : (
              <DataGrid
                rows={testData}
                columns={columns.map((col) => ({
                  ...col,
                  width: testColumnWidths[col.field] || col.width,
                }))}
                pageSize={10}
                columnVisibilityModel={testColumnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  handleColumnVisibilityChange(newModel, "TestTable")
                }
                onColumnWidthChange={(params) => handleColumnResize(params, "TestTable")}
                sx={{ ...dataGridStyles, mb: 2 }}
                getRowId={(row) => row.customerID}
                getRowClassName={(params) => {
                  if (params.indexRelativeToCurrentPage % 2 === 0) {
                    return "MuiDataGrid-even-row";
                  }
                  return "";
                }}
              />
            )}
          </>
        )}
        {showConversionsTable && (
          <>
            {loading ? (
              <Loader />
            ) : (
              <DataGrid
                rows={conversionsData}
                columns={columns.map((col) => ({
                  ...col,
                  width: conversionsColumnWidths[col.field] || col.width,
                }))}
                pageSize={10}
                columnVisibilityModel={{
                  ...conversionsColumnVisibilityModel,
                  hasFTD: false,
                }}
                onColumnVisibilityModelChange={(newModel) =>
                  handleColumnVisibilityChange(newModel, "ConversionsTable")
                }
                onColumnWidthChange={(params) => handleColumnResize(params, "ConversionsTable")}
                sx={{ ...dataGridStyles, mb: 2 }}
                getRowId={(row) => row.customerID}
                getRowClassName={(params) => {
                  if (params.indexRelativeToCurrentPage % 2 === 0) {
                    return "MuiDataGrid-even-row";
                  }
                  return "";
                }}
              />
            )}
          </>
        )}
        {loading ? (
          <Loader />
        ) : (
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              handleColumnVisibilityChange(newModel, "Leaderboard")
            }
            onColumnWidthChange={(params) => handleColumnResize(params, "Leaderboard")}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 25, page: 0 },
              },
            }}
            pageSizeOptions={[25, 50, 100, { value: -1, label: "All" }]}
            sx={{ ...dataGridStyles, width: isMobile ? "100%" : "100%" }}
            getRowId={(row) => row.id}
            getRowHeight={() => 60}
            getRowClassName={(params) => {
              if (params.row.has_ftd === 1) {
                return "row-green";
              }
              if (params.indexRelativeToCurrentPage % 2 === 0) {
                return "MuiDataGrid-even-row";
              }
              return "";
            }}
          />
        )}
      </Box>
    </LocalizationProvider>
  );
};

export default Leadsboard;
