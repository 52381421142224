export const processMediaBuyerData = (leads, conversions, selectedBuyers) => {
  const mediaBuyersMap = {};

  leads.forEach((entry, index) => {
    const mediaBuyerName = entry.custom4 || 'empty';

    if (!mediaBuyersMap[mediaBuyerName]) {
      mediaBuyersMap[mediaBuyerName] = {
        id: `${mediaBuyerName}-${index}`,
        name: mediaBuyerName,
        leads: 0,
        deposit: 0,
        cr: 0,
      };
    }

    if (selectedBuyers.includes(mediaBuyerName)) {
      mediaBuyersMap[mediaBuyerName].leads += 1;
    }
  });

  conversions.forEach((conversion, index) => {
    const mediaBuyerName = conversion.custom4 || 'empty';
  
    if (mediaBuyersMap[mediaBuyerName] && selectedBuyers.includes(mediaBuyerName)) {
      mediaBuyersMap[mediaBuyerName].deposit += 1;
    }
  });

  Object.keys(mediaBuyersMap).forEach((buyer) => {
    const leads = mediaBuyersMap[buyer].leads;
    const deposits = mediaBuyersMap[buyer].deposit;
    mediaBuyersMap[buyer].cr = leads > 0 ? ((deposits / leads) * 100).toFixed(2) : 0;
  });

  return Object.values(mediaBuyersMap);
};

export const processAffiliateData = (leads, conversions, selectedNames) => {
  const affiliateMap = {};
  leads.forEach((entry, index) => {
    const affiliateName = entry.broker_name || 'unknown';
    const countryCode = entry.country_code || 'unknown';

    if (!affiliateMap[affiliateName]) {
      affiliateMap[affiliateName] = {
        id: `${affiliateName}-${index}`,
        name: affiliateName,
        leads: 0,
        deposit: 0,
        cr: 0,
        geoData: {},
      };
    }

    if (selectedNames.includes(affiliateName)) {
      affiliateMap[affiliateName].leads += 1;
      
      if (!affiliateMap[affiliateName].geoData[countryCode]) {
        affiliateMap[affiliateName].geoData[countryCode] = {
          countryCode: countryCode,
          leads: 0,
          deposit: 0,
          cr: 0,
        };
      }
      affiliateMap[affiliateName].geoData[countryCode].leads += 1;
    }
  });

  conversions.forEach((conversion) => {
    const affiliateName = conversion.brokerName || 'unknown';
    const countryCode = conversion.countryCode || 'unknown';
  
    if (affiliateMap[affiliateName] && selectedNames.includes(affiliateName)) {
      affiliateMap[affiliateName].deposit += 1;
  
      if (countryCode !== 'unknown' && affiliateMap[affiliateName].geoData[countryCode]) {
        affiliateMap[affiliateName].geoData[countryCode].deposit += 1;
      } else if (countryCode !== 'unknown') {
        affiliateMap[affiliateName].geoData[countryCode] = {
          countryCode,
          leads: 0,
          deposit: 1,
          cr: 0,
        };
      }
    }
  });
  

  Object.keys(affiliateMap).forEach((affiliate) => {
    const leads = affiliateMap[affiliate].leads;
    const deposits = affiliateMap[affiliate].deposit;
    affiliateMap[affiliate].cr = leads > 0 ? ((deposits / leads) * 100).toFixed(2) : 0;

    Object.keys(affiliateMap[affiliate].geoData).forEach((country) => {
      const geoLeads = affiliateMap[affiliate].geoData[country].leads;
      const geoDeposits = affiliateMap[affiliate].geoData[country].deposit;
      affiliateMap[affiliate].geoData[country].cr = geoLeads > 0 ? ((geoDeposits / geoLeads) * 100).toFixed(2) : 0;
    });
  });

  return Object.values(affiliateMap);
};


